import { createApp } from 'vue';
import App from './App.vue';
import Router from './router';
import VueParticles from 'vue-particles';
import VueIcon from './utils/icon';


const app = createApp(App)
app.use(VueIcon)
app.use(VueParticles)
app.use(Router)
//Router.isReady().then(() => app.mount('#app'))

fetch("/config/config.json")
.then(response => response.json())
.then(config => {
    app.config.globalProperties.$config = config
    app.mount('#app')
    console.log(config)
})
.catch(`Get config.json error: `, console.error)


