<template>
    <div class="contentItem">
        <LogoItem />
        <ShotsItem />
    </div>
</template>

<script>
import LogoItem from '../components/Logo.vue'
import ShotsItem from '../components/Shots.vue'

export default {
    name: 'ContentItem',
    props: {},
    components: {
      LogoItem,
      ShotsItem
    },
    data() {
        return {
           
        }
    },
    mounted() {
       
    },
    methods: {
       
    },
}
</script>

<style lang="stylus" scoped>
@require '../styles/mixins.styl'
@require '../styles/mode.styl'
p a code
  font-weight 400
  color $accentColor
.contentItem
  padding 0
  margin 0 auto
  position relative
  box-sizing border-box
  height auto
  display flex
  flex-direction column
  align-items center
  justify-contentItem center
  text-align center
  overflow hidden
  background-position center
  background-size cover
</style>
