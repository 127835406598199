<template>
    <div class="LogoItem">
        <img class="logoItem-image" :src="$config.logoItem" />
        <div class="logoItem-bubble">
            <div class="logo-bubble__body">
                <p>{{ hitokotoText }}</p>
            </div>
            <div class="logo-bubble__tile" />
        </div>
        <div class="title-info">
            <div class="title-info__text">
                <h1>{{ $config.title }}</h1>
                <p class="description">
                    {{ $config.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogoItem',
    props: {},
    components: {},
    data() {
        return {
            hitokotoText: this.$config.hitokoto.loadText,
        }
    },
    mounted() {
        this.hitokoto()
    },
    methods: {
        hitokoto() {
            let _self = this
            fetch(_self.$config.hitokoto.url)
                .then(response => response.json())
                .then(data => {
                    _self.hitokotoText = data.hitokoto
                    console.log(data)
                })
                .catch(`Get ${_self.$config.hitokoto.url} error: `, console.error)
        },
    },
}
</script>

<style lang="stylus" scoped>
@require '../styles/mixins.styl'
@require '../styles/mode.styl'

p a code
  font-weight 400
  color $accentColor

.LogoItem
  z-index 2
  width 36%
  max-width 500px
  display flex
  flex-direction column
  align-items center
  justify-content center

  .logoItem-image
    width 141px
    height 141px
    cursor auto
    padding 5px
    border-radius 100%
    transition(all ease 1s)
    box-shadow inset 0 0 10px #000
    &:hover
      transform(rotate(360deg))
    &:hover + .logo-bubble
      opacity 1

  .logoItem-bubble
    opacity 0
    position absolute
    left 50%
    margin-left 100px
    top 30%
    margin-top -200px
    // float up and down
    animation bubbleFloat 2s infinite
    -webkit-animation bubbleFloat 2s infinite
    animation-timing-function ease-in-out
    -webkit-animation-timing-function ease-in-out
    -webkit-animation-direction alternate
    animation-direction alternate
    transition(opacity .4s)
    &__body
      min-width 150px
      max-width 250px
      min-height 80px
      background rgba(0, 0, 0, .3)
      border-radius 10px
      p
        font-size 15px
        padding 10px 20px
        color #fff
        text-align left
        line-height 1.7
    &__tile
      position absolute
      content ''
      margin-left -23px
      top 0
      margin-top 60px
      width 23px
      height 44px
      border-width 0
      border-style solid
      border-top-width 20px
      border-radius 56px 0 0 0
      color rgba(0, 0, 0, .3)
  @keyframes bubbleFloat
    0%
      transform(translateY(0))
    100%
      transform(translateY(10px))
  @-webkit-keyframes bubbleFloat
    0%
      transform(translateY(0))
    100%
      transform(translateY(10px))

  .title-info
    background rgba(0, 0, 0, .3)
    width 100%
    padding 17px
    letter-spacing 0
    border-radius 10px
    box-sizing initial
    white-space nowrap
    min-width 270px
    margin-top 20px
    &__text
      padding-top 0
      padding-bottom 0
      color white
      h1
        display block
        font-size 25px
        font-weight 700
        line-height 20px
        margin-top 0
      p
        font-size 18px
        font-weight 300
        line-height 15px
        margin-bottom 0
</style>
