<template>
  <MainBody />
</template>
<script>
import MainBody from './components/Main.vue'

export default {
  name: 'App',
  components: {
    MainBody
  }
}
</script>
<style src="./styles/theme.styl" lang="stylus"></style>