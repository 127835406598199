<template>
  <div class="footerItem-wrapper">
    <span v-html="$config.footerItem" />
  </div>
</template>
<script>
export default {
    name: 'FooterItem',
    props: {},
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
<style lang="stylus" scoped>
@require '../styles/mixins.styl'

.footerItem-wrapper
  position fixed
  bottom 0
  right 0
  z-index 10
  padding 0.5rem 1.5rem
  text-align center
  line-height 1.5rem
  font-size 14px
  sans-serif()
  color var(--text-color-sub)
  span
    & >>> a
      transition(color .2s ease)
      color var(--text-color-sub)
      &:hover
        color var(--accent-color)
</style>


