<template>
    <nav class="nav-links">
        <!-- <div id="kg-btn">
          <input class='tgl tgl-flip' v-model="switchValue" type='checkbox' />
          <label class='tgl-btn' data-tg-off='PUBLIC' data-tg-on='LOCAL' for='switch'></label>
        </div> -->
    </nav>
</template>

<script>

export default {
    computed: {
        
    },
    data() {
        return {
           switchValue: false
        }
    },
    watch: {
      switchValue: function (newValue, oldValue) {
        console.log(newValue)
        console.log(oldValue)
      }
    },
    methods: {
       show_test(){
        alert(123)
       }
    },
}
</script>

<style lang="stylus">
.tgl 
	display none
	& + .tgl-btn 
		position relative
		display block
		width 4em
		height 2em
		outline 0
		cursor pointer
		&:after 
			left 0
		&:before 
			display none
	&:checked 
		& + .tgl-btn 
			&:after 
				left 50%
.tgl,.tgl *,.tgl :after,.tgl :before,.tgl+.tgl-btn,.tgl:after,.tgl:before 
	-webkit-box-sizing border-box
	-moz-box-sizing border-box
	box-sizing border-box
.tgl ::-moz-selection,.tgl :after::-moz-selection,.tgl :before::-moz-selection,.tgl+.tgl-btn::-moz-selection,.tgl::-moz-selection,.tgl:after::-moz-selection,.tgl:before::-moz-selection 
	background 0 0
.tgl ::selection,.tgl :after::selection,.tgl :before::selection,.tgl+.tgl-btn::selection,.tgl::selection,.tgl:after::selection,.tgl:before::selection 
	background 0 0
.tgl+.tgl-btn:after,.tgl+.tgl-btn:before 
	position relative
	display block
	width 50%
	height 100%
	contentItem ""
.tgl-flip 
	& + .tgl-btn 
		padding 2px
		font-family MyriadSetPro-Thin
		-webkit-transition all .2s ease
		transition all .2s ease
		-webkit-perspective 100px
		-ms-perspective 100px
		perspective 100px
		&:after 
			background #FF3A19
			content attr(data-tg-on)
			-webkit-transform rotateY(-180deg)
			transform rotateY(-180deg)
			-ms-transform rotateY(-180deg)
		&:before 
			background #7FC6A6
			content attr(data-tg-off)
		&:active 
			&:before 
				-webkit-transform rotateY(-20deg)
				transform rotateY(-20deg)
				-ms-transform rotateY(-20deg)
	&:checked 
		& + .tgl-btn 
			&:before 
				-webkit-transform rotateY(180deg)
				transform rotateY(180deg)
				-ms-transform rotateY(180deg)
			&:after 
				left 0
				-webkit-transform rotateY(0)
				transform rotateY(0)
				-ms-transform rotateY(0)
			&:active 
				&:after 
					-webkit-transform rotateY(20deg)
					transform rotateY(20deg)
					-ms-transform rotateY(20deg)
.tgl-flip+.tgl-btn:after,.tgl-flip+.tgl-btn:before 
	position absolute
	position absolute
	top 0
	left 0
	display inline-block
	width 100%
	border-radius 4px
	color #fff
	text-align center
	font-weight 700
	line-height 2em
	-webkit-transition all .4s ease
	transition all .4s ease
	-webkit-backface-visibility hidden
	-ms-backface-visibility hidden
	backface-visibility hidden


</style>
