import { createRouter, createWebHistory } from 'vue-router'
import MainItem from '../components/Main.vue'
import config from '../../public/config/config'



const routes = [
  {
    path: '/',
    name: 'MainItem',
    component: MainItem,
    meta:{title: config.title}
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes:[]
})

router.afterEach((to) => {
  //遍历meta改变title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router
