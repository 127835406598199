import { OhVueIcon, addIcons } from 'oh-vue-icons'
import * as FaIcons from "oh-vue-icons/icons/fa";

const Fa = Object.values({ ...FaIcons })

addIcons( ...Fa)

const VueIcon = {

  install (Vue) {
      Vue.component('v-icon', OhVueIcon)
  }

}

export default VueIcon